import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";
import Collections from "@/views/CollezioniView.vue";
import Colors from "@/views/ColorsView.vue";
import Finishes from "@/views/FinishesView.vue";
import Producibility from "@/views/ProducibilityView.vue";
import Collection from "@/views/CollezioneView.vue";
import Decor from "@/views/DecorativoView.vue";
import Login from "@/views/Login.vue";
import Stocks from "@/views/StocksView.vue";
import NotFound from "@/components/NotFound.vue";
import Forbidden from "@/components/Forbidden.vue";
import NotAuthorized from "@/components/NotAuthorized.vue";
import Store from "@/store";
import {VueNavigationClient} from "@/router/NavigationClient";

const routes = [
    {
        path: "",
        name: "home",
        component: Home,
        meta: {
            requiresAuth: false,
            title: "ABET Toolkit",
        },
    },
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            requiresAuth: false,
            title: "ABET Toolkit",
        },
    },
    {
        path: "/collections",
        name: "collections",
        component: Collections,
        meta: {
            requiresAuth: true,
            requiresRoles: ["user", "admin"],
            title: "ABET Collections",
        },
    },
    {
        path: "/collections/:collezione",
        name: "collezione",
        component: Collection,
        meta: {
            requiresAuth: true,
            requiresRoles: ["user", "admin"],
            title: "ABET ",
        },
    },
    {
        path: "/colors",
        name: "colors",
        component: Colors,
        meta: {
            requiresAuth: true,
            requiresRoles: ["user", "admin"],
            title: "ABET Colors",
        },
    },
    {
        path: "/finishes",
        name: "finishes",
        component: Finishes,
        meta: {
            requiresAuth: true,
            requiresRoles: ["user", "admin"],
            title: "ABET Finishes",
        },
    },
    {
        path: "/producibility",
        name: "producibility",
        component: Producibility,
        meta: {
            requiresAuth: true,
            requiresRoles: ["user", "admin"],
            title: "ABET Producibility",
        },
    },
    {
        path: "/collections/:collezione/:decorativo",
        name: "decor",
        component: Decor,
        meta: {
            requiresAuth: true,
            requiresRoles: ["admin"],
            title: "ABET ",
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            requiresAuth: false,
            title: "ABET Login",
        },
    },
    {
        path: "/stocks",
        name: "stocks",
        component: Stocks,
        meta: {
            requiresAuth: true,
            requiresRoles: ["user", "admin"],
            title: "ABET Stocks",
        },
    },
    {
        path: "/403",
        name: "forbidden",
        component: Forbidden,
        meta: {
            requiresAuth: false,
            title: "403 Forbidden",
        },
    },
    {
        path: "/401",
        name: "not-authorized",
        component: NotAuthorized,
        meta: {
            requiresAuth: false,
            title: "401 Not Authorized",
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFound,
        meta: {
            requiresAuth: false,
            title: "404 Not Found",
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const client = new VueNavigationClient(router);

router.beforeEach((to, from, next) => {
    // if the client is already initialized, do nothing, else initialize it and log the result
    if (!Store.getters["auth/initialized"] && to.meta.requiresAuth) {
        Store.dispatch("auth/initialize", client);
    }

    if (to.meta.requiresAuth) {
        // acquire the token from Azure
        Store.dispatch("auth/token")
            .then(() => {
                return next();
            })
            .catch(() => {
                // if there is a problem while acquiring the token, let the user log in
                Store.dispatch("auth/login")
                    .then(() => {
                        // once the user has logged in, try again to acquire the token
                        Store.dispatch("auth/token")
                            .then(() => {
                                return next();
                            })
                            .catch(() => {
                                return next("/login");
                            });
                    })
                    .catch(() => {
                        return next("/login");
                    });
            });
    } else {
        // auth is not required
        return next();
    }
});

export default router;
