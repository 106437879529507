// import AuthService from "@/services/auth.service";
import {Auth} from "@/services/msal.service";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? {
        status: {loggedIn: true, initialized: true},
        user,
        token: null,
    }
    : {
        status: {loggedIn: false, initialized: false},
        user: null,
        token: null,
    };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async initialize({commit}, client) {
            return Auth.initialize(client).then((data) => {
                this.user = data;
                commit("initialize", data);
                return Promise.resolve(data);
            });
        },

        async login({commit}) {
            return Auth.login()
                .then((user) => {
                    commit("loginSuccess", user);
                    return Promise.resolve(user);
                })
                .catch((error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                });
        },

        logout({commit}) {
            Auth.logout().then(() => {
                commit("logout");
            });
        },
        async token({commit}) {
            return Auth.getToken()
                .then((token) => {
                    commit("tokenSuccess", token);
                    return Promise.resolve(token);
                })
                .catch((error) => {
                    commit("tokenFailure");
                    return Promise.reject(error);
                });
        },
        // verifyToken({ commit }, token) {
        //   return AuthService.verifyToken(token)
        //     .then((payload) => {
        //       commit("tokenSuccess", payload);
        //       return Promise.resolve(payload);
        //     })
        //     .catch((error) => {
        //       commit("tokenFailure");
        //       return Promise.reject(error);
        //     });
        // },
    },
    mutations: {
        initialize(state, user) {
            state.status.initialized = true;
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        tokenSuccess(state, token) {
            state.status.loggedIn = true;
            state.token = token;
        },
        tokenFailure(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
    },
    getters: {
        loggedIn(state) {
            return state.status.loggedIn;
        },
        initialized(state) {
            return state.status.initialized;
        },
        user(state) {
            if (state.user) {
                return state.user;
            }
            return null;
        },
        name(state) {
            if (state.user) {
                return state.user.name;
            }
            return null;
        },
        username(state) {
            if (state.user) {
                return state.user.username;
            }
            return null;
        },
        token(state) {
            if (state.token) {
                return state.token;
            }
            return null;
        },
    },
};
